@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@600;900&family=Open+Sans:wght@400;600;700;800&display=swap');

@font-face {
  font-family: 'effra';
  src: url('./fonts/effra.woff2') format('woff2'),
      url('./fonts/effra.woff') format('woff');
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'effra';
  src: url('./fonts/effra-bold.woff2') format('woff2'),
      url('./fonts/effra-bold.woff') format('woff');
  font-style: normal;
  font-weight: bold;
  font-display: swap;
}

@font-face {
  font-family: 'effra';
  src: url('./fonts/effra-light.woff2') format('woff2'),
      url('./fonts/effra-light.woff') format('woff');
  font-style: normal;
  font-weight: 100;
  font-display: swap;
}

* {
  box-sizing: border-box;
}

body {
  text-rendering: optimizeLegibility;
  margin: 0;
  background: #282B2E;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'effra', sans-serif;
  color: #E9E6D4;
  overflow-x: hidden;
  max-width: 100%;
  width: 100%;
  position: relative;
}

ul, li {
  list-style: none;
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
}

html {
  scroll-behavior: smooth;
}
